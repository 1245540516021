/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';


import 'bootstrap';
import bsCustomFileInput from 'bs-custom-file-input';

// start the Stimulus application
import './bootstrap';

bsCustomFileInput.init();

//Jquery
const $ = require('jquery');
require('bootstrap');
require('@fortawesome/fontawesome-free/js/all.js');

//custom JS
import './js/flash.js';
import './js/cookies.js';
//carousel / slider

console.log('app.js launched');

