//If cookie exist do not show disclaimer
console.log('cookies.js fired');
let cookieConsent = document.querySelector('.ch-cookie-consent');

if (!getCookie('skeletCookie')) {
    cookieConsent.style.display = "block";
}

//Else set cookie when accept button
document.addEventListener('cookie-consent-form-submit-successful', function (e) {    
    if (e.detail.id === "cookie_consent_use_all_cookies") {
        setCookie('skeletCookie', true, 90);
    } else if(e.detail.id === "cookie_consent_use_only_functional_cookies"){
        setCookie('skeletCookie', true, 90);
    }
}, false);


// FUNCTIONS
function setCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    }
    
    else var expires = "";
    document.cookie = name + "=" + value + expires + "; path=/";
}


function getCookie(cname) {
     var name = cname + "=";
    var ca = document.cookie.split(';');
    for ( var i = 0; i < ca.length; i++) {
        var c = ca[i].trim();
        if (c.indexOf(name) == 0)
            return c.substring(name.length, c.length);
    }
    return "";
}

function deleteCookie(name, path, domain) {
console.log('delete cookie');
  if( getCookie( name ) ) {
    document.cookie = name + "=" +
      ((path) ? ";path="+path:"")+
      ((domain)?";domain="+domain:"") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}